// import './header.scss';
import { is_elementor_edit } from "src/js/utils";

const header = document.querySelector('.elementor-location-header');

let direction;
let delta_y = 0;
let activation_point;
let is_sticky = false;
let spacer_inititialized = false;
let state = 'initial';

// let row_1 = header.querySelector('.header_right_row_1');

init();
function init(){
    if( is_elementor_edit() ) return;
    if( !header ) return;

    if( document.body.classList.contains('disable_sticky_header') ) return;

    // console.log('header.js')

    activation_point = header.offsetHeight * 3;

    setTimeout(()=>{
        document.addEventListener('scroll', on_scroll);
    }, 500);

    services_dd();
    
    // row_1.wrapper = wrap(row_1, 'header_row_1_wrapper');
    // row_1.wrapper.style.overflow = 'hidden';
    // row_1.wrapper.style.transition = 'height .5s ease';
}

function on_activate(){
    // row_1.wrapper.style.height = 0;
}

function on_deactivate(){
    // row_1.wrapper.style.height = row_1.offsetHeight + 'px';
}

function on_scroll(){
    
    direction = window.scrollY > delta_y ? 'down' : 'up';
    delta_y = window.scrollY;
    
    if( direction == 'down' ) {

        // scroll down

        if( !is_sticky && window.scrollY > activation_point ) {
            sticky_activate();
        }
        
        hide();
    }
    else {

        // scroll up
        
        if( is_sticky && window.scrollY <= activation_point ) {
            on_deactivate();
        }

        if( window.scrollY == 0 ) {
            sticky_deactivate();
        }
        
        show();
    }
}

function spacer_init(){
    if( spacer_inititialized ) return;
    spacer_inititialized = true;

    // console.log('spacer_init');

    if( ['absolute', 'fixed'].indexOf(window.getComputedStyle(header).position) !== -1 ) {
        // no need to add spacer for absolute or fixed
        return;
    };

    let spacer = document.createElement('div');
    spacer.classList.add('site_header_spacer');
    spacer.style.minHeight = header.offsetHeight + 'px';
    header.after(spacer);
    spacer.append(header);
}

function sticky_activate(){

    // console.log('sticky_activate');

    on_activate();

    spacer_init();

    header.style.transform = 'translateY(-100%)';
    header.style.display = 'none';
    // header.classList.add('sticky_active');
    document.body.classList.add('header_sticky_active');

    setTimeout(()=>{
        header.style.display = '';
    }, 50);

    is_sticky = true;
}

function sticky_deactivate(){
    header.style.position = '';
    // header.classList.remove('sticky_active');
    header.style.transform = '';
    document.body.classList.remove('header_sticky_active');
    is_sticky = false;
}

function show(){
    if( state == 'show' ) return;
    if( !is_sticky ) return;
    state = 'show';
    header.style.transform = 'translateY(0)';
    header.style.position = 'fixed';
    header.style.zIndex = 100;
}

function hide(){
    if( state == 'hide' ) return;
    if( !is_sticky ) return;
    state = 'hide';
    header.style.transform = 'translateY(-100%)';
}

function wrap(element, class_name){
    if( !element ) return;
    let wrapper = document.createElement('div');
    wrapper.classList.add(class_name);
    element.after(wrapper);
    wrapper.append(element);
    return wrapper;
}


function services_dd() { 

    let link = header.querySelector('.dd-services');
    let box = header.querySelector('.hidden-menu-service ');
    let nav = header.querySelectorAll(".elementor-nav-menu .menu-item:not(.dd-services)");
     

    link.addEventListener(
        "mouseover",
        (event) => { 
            box.classList.add("active"); 
        },
        false,
    );

    box.addEventListener(
        "mouseleave",
        (event) => { 
            box.classList.remove("active"); 
        },
        false,
    );

    for (let i = 0; i < nav.length; i++) {
        nav[i].addEventListener(
            "mouseover",
            (event) => { 
                box.classList.remove("active"); 
            },
            false,
        );
    } 
 
} 